<template>
  <div id="moomallContent">
    <Navbar :isSearch="isSearch" :isShare="isShare">
      <p class="text-center text-nav">
        {{ this.setNavbar }}
      </p>
    </Navbar>

    <v-main>
      <BasePacer />
      <BaseCardList :itemList="List" :isSquare="isSquare" />
      <scroll-loader
        :loader-method="loading"
        :loader-disable="disable"
        loader-color="#D60000"
      >
      </scroll-loader>
    </v-main>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ConntentsServices from '@/services/ConntentsServices'
import BasePacer from '@/components/Main/BasePacer.vue'

export default {
  name: 'Content',
  components: { BasePacer },
  data() {
    return {
      isSearch: true,
      isShare: true,
      List: [],
      setNavbar: 'moomall Guide',
      disable: false,
      page: 1,
      pageSize: 10,
      totalItems: 0,
    }
  },
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    isSquare: {
      type: [Boolean, String],
      default: false,
    },
  },
  computed: {
    ...mapGetters({ MoomallContent: 'MoomallContentStore/MoomallContent' }),
  },
  created() {
    switch (this.MoomallContent.sort) {
      case 1:
        this.setNavbar =
          this.$i18n.locale === 'th_TH'
            ? this.MoomallContent.title_th.replace(/<[^>]+>/g, '')
            : this.MoomallContent.title_en.replace(/<[^>]+>/g, '')
        break
      case 2:
        this.setNavbar =
          this.$i18n.locale === 'th_TH'
            ? this.MoomallContent.title_th.replace(/<[^>]+>/g, '')
            : this.MoomallContent.title_en.replace(/<[^>]+>/g, '')
        break
      case 3:
        this.setNavbar =
          this.$i18n.locale === 'th_TH'
            ? this.MoomallContent.title_th.replace(/<[^>]+>/g, '')
            : this.MoomallContent.title_en.replace(/<[^>]+>/g, '')
        break
      default:
        break
    }
  },
  methods: {
    loading() {
      ConntentsServices.getContentsByFooterId({
        id: this.id,
        page: this.page++,
        limit: this.pageSize,
      })
        .then(resp => {
          if (resp.status === 200) {
            this.List.push(...resp.data.items)
            this.totalItems = resp.data.meta.totalItems
            // Stop scroll loading...
            if (this.List.length === this.totalItems) {
              this.disable = true
            }
          }
        })
        .catch(err => {
          this.$swal
            .fire({
              icon: 'error',
              title: `${err.message}`,
              showDenyButton: false,
            })
            .then(result => {
              if (result.isConfirmed) {
                this.$router.push({ name: 'News' })
              }
            })
        })
    },
  },
}
</script>

<style scoped>
.text-nav {
  line-height: 1.25rem;
  margin-bottom: 0px;
}
</style>
